import { ReactNode } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  MouseSensor,
  Modifier,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

interface IProps {
  /** 需要传递的列表 */
  list: string[];
  children: ReactNode;
  onDragEnd: (arr: string[], ids: string[]) => void;
}

/** 参考官网：https://docs.dndkit.com/presets/sortable */
/**
 * 列表排序
 */
export const DragContainer = ({ list = [], children, onDragEnd }: IProps) => {
  // 指定传感器，默认是全部
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  // 拖拽结束
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const oldIndex = list.findIndex((item) => item === active.id);
      const newIndex = list.findIndex((item) => item === over?.id);
      const ids: string[] = list.map((item) => item);
      [ids[newIndex], ids[oldIndex]] = [ids[oldIndex], ids[newIndex]];
      const _val = arrayMove(list, oldIndex, newIndex);
      onDragEnd(_val, ids);
    }
  };
  const restrictToContainer: Modifier = ({ transform, activeNodeRect, containerNodeRect }) => {
    if (!containerNodeRect || !activeNodeRect) {
      return transform;
    }
    const { top, left, bottom, right } = containerNodeRect;
    // const { width, height } = activeNodeRect
    const constrainedTransform = {
      ...transform,
      x: Math.min(Math.max(transform.x, left - activeNodeRect.left), right - activeNodeRect.right),
      y: Math.min(Math.max(transform.y, top - activeNodeRect.top), bottom - activeNodeRect.bottom),
    };
    return constrainedTransform;
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToContainer]}>
      <SortableContext items={list}>{children}</SortableContext>
    </DndContext>
  );
};
