import React, { useMemo, useLayoutEffect } from 'react';
import { Table as AntdTable, Tooltip } from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import { useBoolean, useLocalStorageState } from 'ahooks';
import { prefixCls } from './constants';
import { FaArrowsRotate, FaArrowsLeftRightToLine, FaTable } from 'react-icons/fa6';
import {
  QueryTableProps,
  ActionEnum,
  RequestStatus,
  QueryTableRenderComponentType,
  ICustomColumns,
} from './types';
import { useState, useContextDispatch } from './context';
import { useAutoResize } from './hooks';
import { TableRef } from 'antd/es/table';
import clsx from 'clsx';
import { InnerImage as Image } from './components/Image';
import { InnerOperation as Operation } from './components/Operation';
import { CustomModal } from './components/Custom';
import { isObject } from 'lodash-es';

const isInnerType = (type: QueryTableRenderComponentType | string) =>
  ['Image', 'Operation'].includes(type);

const matchInnerComponent = (type: QueryTableRenderComponentType) => {
  const InnerComponents: Record<QueryTableRenderComponentType, any> = {
    Image,
    Operation,
  };
  return InnerComponents[type];
};

export function Table({
  columns: _columns = [],
  dataSource,
  pagination,
  className,
  customColumns,
  tableId = '',
  rowSelection,
  useAutoWidth = false,
  scroll,
  childrens,
  tabsNode,
  extraRightTool,
  defaultTableHandleActions = ['refresh', 'size', 'custom'],
  ...rest
}: QueryTableProps<AnyObject>) {
  const { requestInfo, table, pagination: paginationData, id } = useState();
  const dispatch = useContextDispatch();
  const [open, { setTrue: showCustomModal, setFalse: hideCustomModal }] = useBoolean(false);
  const tableRef = React.useRef<TableRef | null>(null);
  const [size, setSize] = React.useState<'middle' | 'small'>('middle');

  // 初始化数据列
  const columns = useMemo(() => {
    return _columns.map((col, index) => ({
      ...col,
      dataIndex: col.dataIndex || `dataIndex-${index}`,
    }));
  }, [_columns]);

  // 初始化自定义列
  const customerColumns = useMemo(() => {
    return columns.map((item) => {
      return {
        label: item.title as string,
        value: String(item.key || item.dataIndex || ''),
        disabled: !!item.disabledCustom,
      };
    });
  }, [columns]);

  // 计算tableid
  const computedTableId = useMemo(() => {
    return tableId || id;
  }, [tableId, id]);

  // 获取列描述 Key 集合
  const columnsKeys = React.useMemo(
    () => columns.map((col) => String(col.key || col.dataIndex)),
    [columns],
  );

  // 是否开启组队列宽计算 (待有数据后开始初始化)
  const enableAutoWidth = Boolean(useAutoWidth);

  const { autoResizeScroll, rowSelectionWidth } = useAutoResize({
    key: `AUTO_RESIZE_WIDTH_${computedTableId}`,
    enable: enableAutoWidth,
    rowSelect: !!rowSelection,
    tableRef,
  });

  // 根据自定义列checkedColumnsKeys初始化展示列
  const defaultColumns = useMemo(() => {
    if (isObject(customColumns) && customColumns?.checkedColumnsKeys) {
      return customerColumns.filter((item) => {
        const filterKey = String(item.value || '');
        return customColumns?.checkedColumnsKeys?.indexOf(filterKey) !== -1;
      });
    }
    return customerColumns;
  }, [customerColumns]);

  // 当前展示列 Keys
  const [checkedColumnsKeys, setCheckedColumnsKeys] = useLocalStorageState<
    { label: string; value: string; disabled: boolean }[]
  >(computedTableId, {
    defaultValue: defaultColumns?.length ? defaultColumns : [],
  });

  // 获取「初始」展示列 Keys
  const defaultColumnsKeys: string[] = useMemo(() => {
    // 根据 tableId 获取缓存, 计算展示列
    if (checkedColumnsKeys?.length) {
      return columnsKeys.filter(
        (key) => checkedColumnsKeys!.findIndex((i) => i?.value === key) > -1,
      );
    }
    // 受控展示列 Keys
    if (typeof customColumns === 'object' && Array.isArray(customColumns?.checkedColumnsKeys)) {
      return customColumns.checkedColumnsKeys;
    }
    // 默认展示列 Keys
    return columnsKeys;
  }, [customColumns, columnsKeys, computedTableId]);

  const tableLoading = useMemo(() => requestInfo?.status === RequestStatus.START, [requestInfo]);

  const magicColumns = useMemo(() => {
    // 遍历处理 column
    const handleMapAction = (col: (typeof columns)['0']) => {
      const { renderType, renderProps = {} } = col;
      // NOTE: 根据 useCustomWidth 开关条件返回数值
      // 普通渲染
      if (!renderType || !isInnerType(renderType)) {
        return {
          ...col,
        };
      }
      // 根据类型渲染
      return {
        ...col,
        // 生成render函数
        render: (value: any, cols: any, index: number) => {
          // 保证用户自定义的组件优先级更高
          const Comp = matchInnerComponent(renderType as QueryTableRenderComponentType);
          return (
            <Comp
              value={value}
              column={cols}
              index={index}
              {...(typeof renderProps === 'function' ? renderProps(cols, index) : renderProps)}
            />
          );
        },
      };
    };
    // 默认原始自定义列逻辑
    if (checkedColumnsKeys) {
      return checkedColumnsKeys
        ?.map((col) => {
          const item = columns.filter((c) => {
            const filterKey = String(c.key || c.dataIndex || '');
            return col.value === filterKey;
          })[0];
          return item;
        })
        .filter((item) => item)
        .map(handleMapAction);
    }
    console.log(defaultColumnsKeys);
    return defaultColumnsKeys
      .map((col) => {
        const item = columns.filter((c) => {
          const filterKey = String(c.key || c.dataIndex || '');
          return col === filterKey;
        })[0];
        return item;
      })
      .filter((item) => item)
      .map(handleMapAction);
  }, [checkedColumnsKeys, columns, customColumns, defaultColumnsKeys]);

  const onRefresh = () => {
    dispatch({
      type: ActionEnum.REQUEST_START,
      payload: {},
    });
  };

  const onChangeColumns = (arr: ICustomColumns[]) => {
    const result = arr
      .map((item) => {
        return customerColumns.find((i) => i.value === item.value) ? item : null;
      })
      .filter(Boolean);
    setCheckedColumnsKeys(result as ICustomColumns[]);
  };

  const onChangeSize = () => {
    setSize((curr) => (curr === 'middle' ? 'small' : 'middle'));
  };

  const actionToMap = React.useMemo(() => {
    return {
      refresh: {
        tooltip: '刷新',
        IconComp: <FaArrowsRotate onClick={onRefresh} className={`${prefixCls}-icon`} />,
      },
      size: {
        tooltip: '大小',
        IconComp: (
          <FaArrowsLeftRightToLine onClick={onChangeSize} className={`${prefixCls}-icon`} />
        ),
      },
      custom: {
        tooltip: '自定义列',
        IconComp: <FaTable onClick={showCustomModal} className={`${prefixCls}-icon`} />,
      },
    } as Record<string, { tooltip: string; IconComp: JSX.Element }>;
  }, []);

  useLayoutEffect(() => {
    dispatch({
      type: ActionEnum.SAVE_OPTIONS,
      payload: {
        table: {
          ...table,
          checkedColumnsKeys,
        },
      },
    });
  }, [checkedColumnsKeys, dispatch, table]);

  useLayoutEffect(() => {
    dispatch({
      type: ActionEnum.SAVE_OPTIONS,
      payload: {
        table: {
          columns,
        },
      },
    });
  }, [columns, dispatch, table]);

  return (
    <div className={prefixCls}>
      <div className={`${prefixCls}-action`}>
        <div className={`${prefixCls}-action__left`}>{tabsNode}</div>
        <div className={`${prefixCls}-action__right`}>
          {extraRightTool}
          {defaultTableHandleActions?.map((item: string) => {
            const { tooltip, IconComp } = actionToMap[item];
            return (
              <Tooltip key={item} autoAdjustOverflow title={tooltip}>
                <>{IconComp}</>
              </Tooltip>
            );
          })}
        </div>
      </div>
      <AntdTable
        ref={tableRef}
        columns={magicColumns}
        dataSource={dataSource || table?.data || []}
        size={size}
        loading={tableLoading}
        className={clsx(prefixCls, className)}
        pagination={
          typeof pagination === 'boolean'
            ? pagination
            : {
                current: paginationData.current,
                pageSize: paginationData.pageSize,
                total: paginationData.total,
                size: 'default',
                showTotal: (total) => `共${total}条`,
                showQuickJumper: true,
                showTitle: true,
                showSizeChanger: true,
                ...pagination,
                onChange: (pageNumber, pageSize) => {
                  pagination?.onChange?.(pageNumber, pageSize);
                  dispatch({
                    type: ActionEnum.PAGINATION_SET,
                    payload: {
                      offset: (pageNumber - 1) * pageSize,
                      limit: pageSize,
                    },
                  });
                },
              }
        }
        rowSelection={
          rowSelection
            ? {
                ...rowSelection,
                columnWidth: rowSelectionWidth,
              }
            : undefined
        }
        scroll={{
          ...scroll,
          ...autoResizeScroll,
        }}
        {...rest}>
        {childrens}
      </AntdTable>
      <CustomModal
        open={open}
        columns={customerColumns}
        activeKey={checkedColumnsKeys?.map((item) => item.value) || []}
        activeColumns={checkedColumnsKeys}
        onCancel={hideCustomModal}
        onChange={onChangeColumns}
      />
    </div>
  );
}
