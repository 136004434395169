import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { FaGripVertical } from 'react-icons/fa6';

export const DragItem = ({
  targetId,
  children,
}: {
  targetId: string;
  children?: React.ReactNode;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: targetId,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  };

  return (
    <div
      ref={setNodeRef}
      className="query-table-custom-modal-drag-item"
      style={style}
      {...attributes}>
      <FaGripVertical
        className="query-table-custom-modal-icon"
        {...listeners}
        style={{ cursor: 'grab', fontSize: 12 }}
      />
      {children}
    </div>
  );
};
