import { useEffect, useMemo, useState } from 'react';
import { useMemoizedFn } from 'ahooks';
import { Modal, Checkbox, Typography } from 'antd';
import type { ModalProps } from 'antd';
import { FaX, FaLock, FaUnlock } from 'react-icons/fa6';
import { isUndefined } from 'lodash-es';
import { DragContainer } from './DragContainer';
import { DragItem } from './DragItem';
import { ICustomColumns } from '../../types';

const { Paragraph } = Typography;

enum AllCheckEnum {
  NOT = 0,
  NOT_ALL = 1,
  ALL = 2,
}

interface IProps extends ModalProps {
  activeKey?: string[];
  columns?: ICustomColumns[];
  activeColumns?: ICustomColumns[];
  onChange?: (columns: ICustomColumns[]) => void;
}

export const CustomModal = (props: IProps) => {
  const {
    activeKey,
    onChange,
    columns: _columns = [],
    open,
    onCancel,
    activeColumns,
    ...rest
  } = props;
  // 选中状态
  const [allCheckType, setAllCheckType] = useState<AllCheckEnum>();
  // 自定义列中选中后的key
  const [value, setValue] = useState<string[]>(activeKey ?? []);
  // 自定义列选中列
  const [checkedColumns, setCheckedColumns] = useState<ICustomColumns[]>([]);
  // 待处理列
  const columns = useMemo(() => {
    return _columns?.map((item) => {
      const activeCol = checkedColumns?.filter((col) => col.value === item.value)?.[0];
      return {
        ...item,
        disabled: isUndefined(activeCol?.disabled) ? item.disabled : activeCol.disabled,
      };
    });
  }, [_columns, checkedColumns]);

  const onSubmit = useMemoizedFn((e) => {
    onCancel?.(e);
    onChange?.(checkedColumns);
  });

  const notCheckedCount = useMemo(() => {
    if (!columns?.length) return 0;
    return columns.length - value.length;
  }, [columns, value]);

  const changeCheck = useMemoizedFn((e) => {
    if (e.target.checked) {
      const extraColumns = columns
        ?.filter((col) => !value.includes(col.value))
        .map((item) => item.value);
      setValue((prev) => [...prev, ...extraColumns]);
    } else {
      setValue(columns?.filter((item) => item.disabled)?.map((item) => item.value) || []);
    }
  });
  const handleDragEnd = (_list: string[]) => {
    setValue(_list);
  };

  const changeDisabled = useMemoizedFn((key: string, disabled: boolean) => {
    setCheckedColumns(
      (prev) =>
        prev?.map((item) => {
          if (item.value === key) {
            return {
              ...item,
              disabled,
            };
          }
          return item;
        }) || [],
    );
  });

  useEffect(() => {
    let status = AllCheckEnum.NOT;
    if (value?.length === columns?.length) {
      status = AllCheckEnum.ALL;
    } else if (value?.length) {
      status = AllCheckEnum.NOT_ALL;
    }
    setAllCheckType(status);
  }, [columns, value]);

  useEffect(() => {
    setCheckedColumns(
      () =>
        value
          .map((item) => {
            const col = _columns?.filter((col) => col.value === item)?.[0];
            const activeCol = activeColumns?.filter((col) => col.value === item)?.[0];
            if (col) {
              return activeCol ? activeCol : col;
            }
            return null;
          })
          .filter(Boolean) as ICustomColumns[],
    );
  }, [_columns, value, activeColumns]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      width={700}
      className="query-table-custom-modal"
      title="自定义列表"
      okText="保存"
      cancelText="取消"
      onOk={onSubmit}
      {...rest}>
      <div className="query-table-custom-modal-container">
        <div className="query-table-custom-modal-left">
          <div className="query-table-custom-modal-tital">
            <div className="tital">
              待选项
              <span className="count">（{`${notCheckedCount}/${columns?.length}`}）</span>
            </div>
            <Checkbox
              checked={allCheckType === AllCheckEnum.ALL}
              indeterminate={allCheckType === AllCheckEnum.NOT_ALL}
              onChange={changeCheck}>
              全选
            </Checkbox>
          </div>
          <Checkbox.Group
            className="query-table-custom-modal-checkbox-group"
            value={value}
            onChange={(e) => {
              console.log(e);
              setValue(e);
            }}>
            {columns?.map((item) => (
              <Checkbox key={item.value} value={item.value} disabled={item.disabled}>
                <Paragraph style={{ maxWidth: 100 }} ellipsis={{ tooltip: item.label, rows: 1 }}>
                  {item.label}
                </Paragraph>
              </Checkbox>
            ))}
          </Checkbox.Group>
        </div>
        <div className="query-table-custom-modal-right">
          <div className="query-table-custom-modal-tital">
            <div className="tital">
              已选
              <span className="count">（{`${value?.length}/${columns?.length}`}）</span>
            </div>
          </div>
          <div className="query-table-custom-modal-checked">
            <DragContainer list={value} onDragEnd={handleDragEnd}>
              {checkedColumns?.map((item) => (
                <DragItem key={item.value} targetId={item.value}>
                  <div className="query-table-custom-modal-drag-item-content">
                    <Paragraph ellipsis={{ tooltip: item.label, rows: 1 }}>{item.label}</Paragraph>
                    <div className="operator">
                      {item.disabled ? (
                        <FaLock
                          className="query-table-custom-modal-icon"
                          onClick={() => changeDisabled(item.value, false)}
                        />
                      ) : (
                        <>
                          <FaX
                            className="query-table-custom-modal-icon"
                            style={{ marginRight: 8 }}
                            onClick={() => {
                              setValue(value.filter((v) => v !== item.value));
                            }}
                          />
                          <FaUnlock
                            className="query-table-custom-modal-icon"
                            onClick={() => changeDisabled(item.value, true)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </DragItem>
              ))}
            </DragContainer>
          </div>
        </div>
      </div>
    </Modal>
  );
};
